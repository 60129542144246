:local(.container) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

  :local(.container) :local(.loading-text) {
    width: 100%;
    text-align: center;
  }

  :local(.container) :local(.loading-bar) {
    height: 3px;
    width: 0%;
    background-color: #efefef;
  }
