:local(.container) {
  width: 100%;
  height: 100vh;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

  :local(.container) :local(.locked) {
    margin-top: -15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    color: rgba(255, 255, 255, .3);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 10vw;
    font-weight: bold;
    text-shadow: 0px 0px 10px rgba(255, 145, 20, 1);

    -webkit-animation-duration: 300ms;

            animation-duration: 300ms;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.15, 1.21);
            animation-timing-function: cubic-bezier(0, 0, 0.15, 1.21);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  :local(.container) :local(.locked) :local {
      -webkit-animation-name: locked-show;
              animation-name: locked-show;
    }

  :local(.container) :local(.disc) {
    width: 50vh;
    height: 50vh;
    min-width: 250px;
    min-height: 250px;
    border: 2px solid white;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: opacity 300ms ease-out;
    transition: opacity 300ms ease-out
  }

  :local(.container) :local(.disc):local(.locked-disc) {
  opacity: 0.3;
}

  :local(.container) :local(.disc) > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  z-index: 0;
  opacity: 0;
}

  :local(.container) :local(.disc) > img:local(.image-show) {
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.15, 1.21);
            animation-timing-function: cubic-bezier(0, 0, 0.15, 1.21);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.disc) > img:local(.image-show) :local {
          -webkit-animation-name: image-show;
                  animation-name: image-show;
}

  :local(.container) :local(.details) {
    text-align: center;
    color: #efefef;
    width: 100%;
    text-shadow: #212121 1px 1px 1px, #212121 -1px 1px 1px,
      #212121 1px -1px 1px, #212121 -1px -1px 1px;
    margin-top: -50px;
    z-index: 10
  }

  :local(.container) :local(.details).locked {
  opacity: 0.3;
}

  :local(.container) :local(.details) :local(.values) {
      text-align: center;
      width: 100%;
      line-height: 1;
      letter-spacing: 0px;
}

  :local(.container) :local(.details) :local(.values):local(.show) {
      -webkit-animation-duration: 300ms;
              animation-duration: 300ms;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
}

  :local(.container) :local(.details) :local(.values):local(.show) :local {
          -webkit-animation-name: show;
                  animation-name: show;
}

  :local(.container) :local(.details) :local(.values) :local(.title) {
        font-size: x-large;
        overflow: hidden;
        white-space: nowrap;
}

  :local(.container) :local(.details) :local(.values) :local(.artist) {
        font-size: larger;
        overflow: hidden;
        white-space: nowrap;
}

  :local(.container) :local(.remixes) {
    width: 40%;
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  :local(.container) :local(.remixes) :local(.cross-fader) {
      color: #efefef;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(span) {
        font-size: xx-large;
      }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.a) {
        color: rgba(136, 198, 19, 1);
      }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.b) {
        color: rgba(255, 145, 20, 1);
      }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.disabled) {
        color: gray;
      }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.fader) {
        display: inline-block;
        margin: 0px 20px 0px 20px;
        width: 100%;
        height: 34px;
        background-image: url('/assets/images/scale-bar.png');
        background-repeat: repeat-x;
        background-position: left center;
      }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.fader) :local(.pick) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          border-radius: 10%;
          background-color: #212121;
          width: 20px;
          height: 34px;
          border: 1px solid rgba(255, 255, 255, .3);
        }

  :local(.container) :local(.remixes) :local(.cross-fader) :local(.fader) :local(.pick) :local(.bar) {
            display: inline-block;
            background-color: #efefef;
            height: 100%;
            width: 3px;
          }

@-webkit-keyframes :local(show) {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 0px;
  }
}

@keyframes :local(show) {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 0px;
  }
}

@-webkit-keyframes :local(image-show) {
  0% {
    opacity: 0;
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
}

@keyframes :local(image-show) {
  0% {
    opacity: 0;
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
}

@-webkit-keyframes :local(locked-show) {
  0% {
    -webkit-transform: translate3d(0px, -20px, 0px);
            transform: translate3d(0px, -20px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes :local(locked-show) {
  0% {
    -webkit-transform: translate3d(0px, -20px, 0px);
            transform: translate3d(0px, -20px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
