:local(.container) {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  :local(.container) :local(.animation-container) {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  :local(.container) :local(.menu-list) {
    margin-right: 10%;
    /* transform: perspective(500px) rotateY(-23deg); */
    z-index: 50;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }

  :local(.container) :local(.menu-list) :local {
      -webkit-animation-name: show;
              animation-name: show;
    }

  :local(.container) :local(.menu-list) > span {
  display: block;
  cursor: pointer;
  position: relative;
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
}

  :local(.container) :local(.menu-list) > span:local(.hide-menu-text) {
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
  opacity: 0;
}

  :local(.container) :local(.menu-list) > span :local(.under-development) {
        color: rgba(255, 255, 255, .3);
}

  :local(.container) :local(.menu-list) > span :local(.under-development)::after {
  content: 'under development';
  display: block;
  font-size: 15px;
  position: absolute;
  top: 18px;
  pointer-events: none;
  color: #efefef;
  text-shadow: 0px 0px 10px #212121;
}

  :local(.container) :local(.menu-list) > span:nth-child(2) :local {
        -webkit-animation-name: slide50;
                animation-name: slide50;
}

  :local(.container) :local(.menu-list) > span:nth-child(3) :local {
        -webkit-animation-name: slide100;
                animation-name: slide100;
}

  :local(.container) :local(.menu-list) > span > p {
  margin: 0;
  font-weight: bold;
  font-size: xx-large;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-name: shadow-flickering;
            animation-name: shadow-flickering;
}

  :local(.container) :local(.menu-list):local(.selected) {
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.menu-list):local(.selected) :local {
        -webkit-animation-name: hide;
                animation-name: hide;
}

  :local(.container) :local(.menu-list):local(.selected) > span {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
}

  :local(.container) :local(.menu-list):local(.selected) > span:nth-child(2) :local {
          -webkit-animation-name: slide50-reverse;
                  animation-name: slide50-reverse;
}

  :local(.container) :local(.menu-list):local(.selected) > span:nth-child(3) :local {
          -webkit-animation-name: slide100-reverse;
                  animation-name: slide100-reverse;
}

  :local(.container) :local(.play-dj-mode-container) {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    font-size: 20px
  }

  :local(.container) :local(.play-dj-mode-container) > :local(span) {
  text-shadow: 2px 2px 6px #212121;
  bottom: 20vh;
  position: absolute;
    -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  -webkit-transition: opacity ease-out 1000ms;
  transition: opacity ease-out 1000ms;
}

  :local(.container) :local(.play-dj-mode-container) > :local(span) :local {
        -webkit-animation-name: blink;
                animation-name: blink;
}

  :local(.container) :local(.play-dj-mode-container) > :local(span):local(.selected) {
  opacity: 0;
}

  :local(.container) :local(.play-dj-mode-container) > :local(span):local(.selected) :local {
          -webkit-animation-name: fade-out;
                  animation-name: fade-out;
          -webkit-animation-duration: 400ms;
                  animation-duration: 400ms;
          -webkit-animation-iteration-count: 1;
                  animation-iteration-count: 1;
}

  :local(.container) :local(.credit-container) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 51
  }

  :local(.container) :local(.credit-container).show {
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-timing-function: cubic-bezier(0.13, 0.72, 0.58, 1.28);
            animation-timing-function: cubic-bezier(0.13, 0.72, 0.58, 1.28);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.credit-container).show :local {
        -webkit-animation-name: credit-show;
                animation-name: credit-show;
}

  :local(.container) :local(.credit-container).hide {
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.credit-container).hide :local {
        -webkit-animation-name: credit-hide;
                animation-name: credit-hide;
}

  :local(.container) :local(.credit-container) :local(.credit-body) {
      width: calc(100% - 10vh);
      height: calc(100% - 10vh);
      margin: 5vh;
      border-radius: 3px;
      border: 1px solid white;
      background-color: rgba(0, 0, 0, .8);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}

  :local(.container) :local(.credit-container) :local(.credit-body) :local(.ncs) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }

  :local(.container) :local(.credit-container) :local(.credit-body) :local(.ncs) :local(img) {
          width: 20vh;
          height: 20vh;
        }

  :local(.container) :local(.credit-container) :local(.credit-body) :local(.ncs) :local(div) {
          margin-left: 15px;
        }

  :local(.container) :local(.footer) {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 0px;
    width: 100%;
    margin-bottom: 5px;
  }

  :local(.container) :local(.footer) :local(span) {
      margin: 0px 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

  :local(.container) :local(.footer) :local(span) :local(.github-icon) {
        width: 16px;
        height: 16px;
        display: inline-block;
        background-image: url(/assets/images/github-icon.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

@-webkit-keyframes :local(show) {
  0% {
    margin-right: -20%;
  }
  100% {
    margin-right: 10%;
  }
}

@keyframes :local(show) {
  0% {
    margin-right: -20%;
  }
  100% {
    margin-right: 10%;
  }
}

@-webkit-keyframes :local(hide) {
  0% {
    margin-right: 10%;
  }
  100% {
    margin-right: -50%;
  }
}

@keyframes :local(hide) {
  0% {
    margin-right: 10%;
  }
  100% {
    margin-right: -50%;
  }
}

@-webkit-keyframes :local(slide50) {
  0% {
    padding-left: 50px;
  }
  100% {
    padding-left: 0px;
  }
}

@keyframes :local(slide50) {
  0% {
    padding-left: 50px;
  }
  100% {
    padding-left: 0px;
  }
}

@-webkit-keyframes :local(slide100) {
  0% {
    padding-left: 100px;
  }
  100% {
    padding-left: 0px;
  }
}

@keyframes :local(slide100) {
  0% {
    padding-left: 100px;
  }
  100% {
    padding-left: 0px;
  }
}

@-webkit-keyframes :local(slide50-reverse) {
  0% {
    padding-left: 0px;
  }
  100% {
    padding-left: 50px;
  }
}

@keyframes :local(slide50-reverse) {
  0% {
    padding-left: 0px;
  }
  100% {
    padding-left: 50px;
  }
}

@-webkit-keyframes :local(slide100-reverse) {
  0% {
    padding-left: 0px;
  }
  100% {
    padding-left: 100px;
  }
}

@keyframes :local(slide100-reverse) {
  0% {
    padding-left: 0px;
  }
  100% {
    padding-left: 100px;
  }
}

@-webkit-keyframes :local(credit-show) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0px, 10vh, 0px);
            transform: translate3d(0px, 10vh, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0vh, 0px);
            transform: translate3d(0px, 0vh, 0px);
  }
}

@keyframes :local(credit-show) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0px, 10vh, 0px);
            transform: translate3d(0px, 10vh, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0vh, 0px);
            transform: translate3d(0px, 0vh, 0px);
  }
}

@-webkit-keyframes :local(credit-hide) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes :local(credit-hide) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes :local(blink) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes :local(blink) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes :local(fade-out) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes :local(fade-out) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
