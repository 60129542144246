:local(.container) {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

  :local(.container) :local(.audio-enabler) {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;
    background-color: rgba(0, 0, 0, .5);
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center
  }

  :local(.container) :local(.audio-enabler):local(.show) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

  :local(.container) :local(.audio-enabler) > img {
  width: 40px;
  margin-right: 20px;
}

  :local(.container) :local(.title) {
    position: absolute;
    top: 5%;
    right: 5%;
    display: block;
    font-weight: bold;
    font-size: xx-large;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    z-index: 10;
  }

  :local(.container) :local(.play-button) {
    position: absolute;
    bottom: 5%;
    right: 5%;
    display: block;
    font-weight: bold;
    font-size: xx-large;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: opacity 300ms ease-out;
    transition: opacity 300ms ease-out
    /* transform: perspective(180px) rotateY(23deg); */
  }

  :local(.container) :local(.play-button):local(.locked) {
  opacity: 0.3;
}

  :local(.container) :local(.midi-button) {
    position: absolute;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: 5%;
    top: calc(50% - 30px);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    z-index: 10;
  }

  :local(.container) :local(.midi-button) :local(img) {
      width: 30px;
    }

  :local(.container) :local(.midi-button):local(.midi-connected) {
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1.2);
            animation-timing-function: cubic-bezier(0, 0, 0, 1.2);
}

  :local(.container) :local(.midi-button):local(.midi-connected) :local {
        -webkit-animation-name: midi-bound;
                animation-name: midi-bound;
}

  :local(.container) :local(.midi-button):local(.midi-connected) > span {
  color: rgba(136, 198, 19, 1);
}

  :local(.container) :local(.share-button) {
    position: absolute;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    right: 5%;
    top: calc(50% - 30px);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    z-index: 10;
  }

  :local(.container) :local(.share-button) :local(img) {
      width: 30px;
    }

  :local(.container) :local(.back-button) {
    position: absolute;
    bottom: 5%;
    left: 5%;
    display: block;
    font-weight: bold;
    font-size: xx-large;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    z-index: 10;
    /* transform: perspective(180px) rotateY(-23deg); */
  }

  :local(.container) :local(.disc-list) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
    transition: -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
    transition: transform 200ms cubic-bezier(0, 0, 0, 1);
    transition: transform 200ms cubic-bezier(0, 0, 0, 1), -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
  }

  :local(.container) :local(.confirm-cover) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 50;
  }

  :local(.container) :local(.confirm-cover) :local(.confirm) {
      width: 100%;
      height: 40%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: rgba(0, 0, 0, .8);
      border-bottom: 2px solid rgba(255, 255, 255, .8);
      border-top: 2px solid rgba(255, 255, 255, .8);
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      justify-content: center;

      -webkit-animation-duration: 300ms;

              animation-duration: 300ms;
      -webkit-animation-fill-mode: backwards;
              animation-fill-mode: backwards;
      -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1.2);
              animation-timing-function: cubic-bezier(0, 0, 0, 1.2);
    }

  :local(.container) :local(.confirm-cover) :local(.confirm) :local {
        -webkit-animation-name: confirm-show;
                animation-name: confirm-show;
      }

  :local(.container) :local(.confirm-cover) :local(.confirm) :local(.answer-set) :local(.disc-title) {
        }

  :local(.container) :local(.confirm-cover) :local(.confirm) :local(.answer-set) :local(.answer) {
          font-weight: bold;
          margin: 20px
        }

  :local(.container) :local(.confirm-cover) :local(.confirm) :local(.answer-set) :local(.answer):local(.play) {
  font-size: xx-large;
}

  :local(.container) :local(.arrival-container) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 20;
  }

  :local(.container) :local(.arrival-container) :local(.arrival-items) {
      background-color: rgba(0, 0, 0, .8);
      width: 100%;
      height: 50vh;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;

      -webkit-animation-duration: 200ms;

              animation-duration: 200ms;
      -webkit-animation-fill-mode: backwards;
              animation-fill-mode: backwards;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }

  :local(.container) :local(.arrival-container) :local(.arrival-items) :local {
        -webkit-animation-name: arrival-items-show;
                animation-name: arrival-items-show;
      }

  :local(.container) :local(.arrival-container).hide :local(.arrival-items) {
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.arrival-container).hide :local(.arrival-items) :local {
        -webkit-animation-name: arrival-items-hide;
                animation-name: arrival-items-hide;
}

  :local(.container) :local(.arrival-container) :local(.arrival-message) {
      width: 50%;
      -webkit-animation-duration: 200ms;
              animation-duration: 200ms;
      animation-offset: 100ms;
      -webkit-animation-fill-mode: backwards;
              animation-fill-mode: backwards;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
}

  :local(.container) :local(.arrival-container) :local(.arrival-message) :local {
        -webkit-animation-name: arrival-message-show;
                animation-name: arrival-message-show;
      }

  :local(.container) :local(.arrival-container) :local(.arrival-message) :local(.unlocked-text) {
        word-break: break-all
      }

  :local(.container) :local(.arrival-container) :local(.arrival-message) :local(.unlocked-text)::before {
  content: '';
  background-image: url(/assets/images/texts/music-unlocked@x2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80vh;
  height: 10vh;
  display: block;
}

  :local(.container) :local(.arrival-container).hide :local(.arrival-message) {
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

  :local(.container) :local(.arrival-container).hide :local(.arrival-message) :local {
        -webkit-animation-name: arrival-message-hide;
                animation-name: arrival-message-hide;
}

  :local(.container) :local(.arrival-container) :local(.confirm-text) {
      font-size: larger;
}

  :local(.container) :local(.tutorial-container) {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  :local(.container) :local(.share-container) {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    -webkit-animation-duration: 100ms;

            animation-duration: 100ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  :local(.container) :local(.share-container) :local {
      -webkit-animation-name: share-container-show;
              animation-name: share-container-show;
    }

  :local(.container) :local(.share-container) :local(.share-box) {
      height: 50%;
      width: 100%;
      background-color: rgba(0, 0, 0, .8);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }

  :local(.container) :local(.share-container) :local(.share-buttons) :local(img) {
        margin: 0px 8px;
      }

  :local(.container) :local(.share-container) :local(.share-close) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      position: absolute;
      right: 30px;
    }

  :local(.container) :local(.share-container) :local(.share-close) :local(.share-close-button) {
        width: 40px;
        height: 40px;
        border: 2px solid white;
        background-color: #212121;
        border-radius: 100%;
        background-image: url(/assets/images/cross-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
      }

@-webkit-keyframes :local(confirm-show) {
  0% {
    -webkit-transform: translate3d(0px, 50px, 0px);
            transform: translate3d(0px, 50px, 0px);
    opacity: 0;
    width: 80%;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    width: 100%;
  }
}

@keyframes :local(confirm-show) {
  0% {
    -webkit-transform: translate3d(0px, 50px, 0px);
            transform: translate3d(0px, 50px, 0px);
    opacity: 0;
    width: 80%;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    width: 100%;
  }
}

@-webkit-keyframes :local(arrival-items-show) {
  0% {
    height: 25vh;
    opacity: 0;
  }
  100% {
    height: 50vh;
    opacity: 1;
  }
}

@keyframes :local(arrival-items-show) {
  0% {
    height: 25vh;
    opacity: 0;
  }
  100% {
    height: 50vh;
    opacity: 1;
  }
}

@-webkit-keyframes :local(arrival-items-hide) {
  0% {
    height: 50vh;
    opacity: 1;
  }
  100% {
    height: 25vh;
    opacity: 0;
  }
}

@keyframes :local(arrival-items-hide) {
  0% {
    height: 50vh;
    opacity: 1;
  }
  100% {
    height: 25vh;
    opacity: 0;
  }
}

@-webkit-keyframes :local(arrival-message-show) {
  0% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@keyframes :local(arrival-message-show) {
  0% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@-webkit-keyframes :local(arrival-message-hide) {
  0% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
}

@keyframes :local(arrival-message-hide) {
  0% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
}

@-webkit-keyframes :local(share-container-show) {
  0% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@keyframes :local(share-container-show) {
  0% {
    -webkit-transform: translate3d(60px, 0px, 0px);
            transform: translate3d(60px, 0px, 0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@-webkit-keyframes :local(midi-bound) {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes :local(midi-bound) {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
