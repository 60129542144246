:local(.container) {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  :local(.container) :local(.circle-visualizer-container) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  :local(.container) :local(.effector-container) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  :local(.container) :local(.effector-container) :local(.knob-container) {
    }

  :local(.container) :local(.systemButtons) {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  :local(.container) :local(.systemButtons) :local(span) {
      font-weight: bold;
      font-size: 27px;
      cursor: pointer;
    }
