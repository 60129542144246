:local(.container) {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  :local(.container) :local(.load-state) {
    position: absolute;
    bottom: 0px;
    left: 0px;
    font-size: smaller;
  }

:local(.container):local(.tutorial-show) > :local(.load-state) {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="1.5" /></filter></svg>#filter');
  -webkit-filter: blur(1.5px);
          filter: blur(1.5px);
}

:local(.container) :local(.touch-to-start) {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

:local(.container) :local(.touch-to-start) :local(.text) {
      -webkit-animation-duration: 5s;
              animation-duration: 5s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-name: shadow-flickering;
              animation-name: shadow-flickering;
    }

:local(.container):local(.tutorial-show) > :local(.touch-to-start) {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="1.5" /></filter></svg>#filter');
  -webkit-filter: blur(1.5px);
          filter: blur(1.5px);
}

:local(.container) :local(.tutorial-container) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
}

@-webkit-keyframes shadow-flickering {
  10% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  13% {
    text-shadow: 10px 2px 3px rgba(239, 0, 127, 1);
  }
  14% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  15% {
    text-shadow: 10px -2px 3px rgba(239, 0, 127, 1);
  }
  16% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  18% {
    text-shadow: -5px 4px 3px rgba(239, 0, 127, 1);
  }
  20% {
    text-shadow: 2px 1px 2px rgba(239, 0, 127, 1);
  }
  60% {
    text-shadow: 5px 1px 2px rgba(239, 0, 127, 1);
  }
  61% {
    text-shadow: -1px 1px 2px rgba(239, 0, 127, 1);
  }
  62% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  63% {
    text-shadow: -5px 4px 3px rgba(239, 0, 127, 1);
  }
  64% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  65% {
    text-shadow: 10px -2px 3px rgba(239, 0, 127, 1);
  }
  66% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  68% {
    text-shadow: 10px 2px 3px rgba(239, 0, 127, 1);
  }
  70% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
}

@keyframes shadow-flickering {
  10% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  13% {
    text-shadow: 10px 2px 3px rgba(239, 0, 127, 1);
  }
  14% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  15% {
    text-shadow: 10px -2px 3px rgba(239, 0, 127, 1);
  }
  16% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  18% {
    text-shadow: -5px 4px 3px rgba(239, 0, 127, 1);
  }
  20% {
    text-shadow: 2px 1px 2px rgba(239, 0, 127, 1);
  }
  60% {
    text-shadow: 5px 1px 2px rgba(239, 0, 127, 1);
  }
  61% {
    text-shadow: -1px 1px 2px rgba(239, 0, 127, 1);
  }
  62% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  63% {
    text-shadow: -5px 4px 3px rgba(239, 0, 127, 1);
  }
  64% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  65% {
    text-shadow: 10px -2px 3px rgba(239, 0, 127, 1);
  }
  66% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
  68% {
    text-shadow: 10px 2px 3px rgba(239, 0, 127, 1);
  }
  70% {
    text-shadow: 2px 1px 2px rgba(0, 0, 0, .8);
  }
}
