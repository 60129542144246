:local(.container) {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .5);
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

  :local(.container) :local {
    -webkit-animation-name: show;
            animation-name: show;
  }

:local(.container)::before {
  content: attr(data-title);
  margin-top: 75px;
  padding-right: 20px;
  font-size: 25px;
  text-align: right;
}

:local(.container) :local(.slider-container) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: calc(100% - 200px);
    background-color: #212121;
}

:local(.container) :local(.slider-container) :local(.pager) {
      width: 50px;
      height: calc(100% - 200px);
      position: absolute;
      background-image: url(/assets/images/arrow-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-left: 2px solid white;
      background-color: #212121;
      z-index: 10
    }

:local(.container) :local(.slider-container) :local(.pager).left {
  left: 2px;
}

:local(.container) :local(.slider-container) :local(.pager).right {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
  right: 2px;
}

:local(.container) :local(.slider-container) :local(.slider-body) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 100%;
      -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
      transition: -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
      transition: transform 200ms cubic-bezier(0, 0, 0, 1);
      transition: transform 200ms cubic-bezier(0, 0, 0, 1), -webkit-transform 200ms cubic-bezier(0, 0, 0, 1);
    }

:local(.container) :local(.slider-container) :local(.slider-body) :local(.slider) {
        width: 100%;
        height: 100%;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
      }

:local(.container) :local(.slider-container) :local(.slider-body) :local(.slider) > img {
  height: 100%;
  margin-left: 60px;
}

:local(.container) :local(.slider-container) :local(.slider-body) :local(.slider) :local(.detail) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          height: 100%;
          margin-left: 12px;
          margin-right: 60px;
          overflow-y: scroll;
}

:local(.container) :local(.slider-container) :local(.slider-body) :local(.slider) :local(.detail) :local(.detail-title) {
            font-size: 20px;
            margin: 10px 0px;
          }

:local(.container) :local(.slider-container) :local(.slider-body) :local(.slider) :local(.detail) :local(.detail-body) {
            font-size: xx-small;
          }

:local(.container) :local(.tutorial-config) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-right: 20px;
    font-size: smaller;
}

:local(.container) :local(.tutorial-config) :local(.check-box) {
      width: 20px;
      height: 20px;
      background-image: url(/assets/images/checkbox-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%
    }

:local(.container) :local(.tutorial-config) :local(.check-box).checked {
  background-image: url(/assets/images/checkbox-white-checked.svg);
}

:local(.container) :local(.close) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

:local(.container) :local(.close) :local(.button) {
      width: 40px;
      height: 40px;
      border: 2px solid white;
      background-color: #212121;
      border-radius: 100%;
      background-image: url(/assets/images/cross-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40%;
    }

@-webkit-keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
