:local(.container) {
  border: solid 5px black;
  background-color: rgba(0, 0, 0, .3);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .5);
          box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .5);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

  :local(.container) :local(.chip-cursor) {
    width: 40vh;
    height: 40vh;
    border-radius: 100%;
    background: transparent;
    position: relative;
    -webkit-box-shadow: inset rgba(0, 0, 0, .5) 0px 0px 8vh 0px;
            box-shadow: inset rgba(0, 0, 0, .5) 0px 0px 8vh 0px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.54, 0.01, 0.58, 1);
            animation-timing-function: cubic-bezier(0.54, 0.01, 0.58, 1);
    background-image: url(/assets/images/wave-noise.gif);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-transition: opacity 200ms ease-out;
    transition: opacity 200ms ease-out;
  }

  :local(.container) :local(.chip-cursor) :local {
      -webkit-animation-name: bounce;
              animation-name: bounce;
    }

@-webkit-keyframes :local(bounce) {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
            transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes :local(bounce) {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
            transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
