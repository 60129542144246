:local(.container) {
  position: absolute;
  z-index: 30;
  width: 60px;
  height: 60px;
}

  :local(.container) :local(.tip) {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;

    -webkit-animation-duration: 200ms;

            animation-duration: 200ms;
  }

  :local(.container) :local(.tip) :local {
      -webkit-animation-name: show-hide;
              animation-name: show-hide;
    }

  :local(.container) :local(.tip):nth-child(1) {
  background-color: rgba(239, 0, 127, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
}

  :local(.container) :local(.tip):nth-child(2) {
  background-color: rgba(135, 0, 255, 1);
    -webkit-animation-delay: 30ms;
            animation-delay: 30ms;
}

  :local(.container) :local(.tip):nth-child(3) {
  background-color: rgba(0, 157, 255, 1);
    -webkit-animation-delay: 60ms;
            animation-delay: 60ms;
}

  :local(.container) :local(.tip):nth-child(4) {
  background-color: rgba(136, 198, 19, 1);
    -webkit-animation-delay: 90ms;
            animation-delay: 90ms;
}

  :local(.container) :local(.tip):nth-child(5) {
  background-color: rgba(255, 145, 20, 1);
    -webkit-animation-delay: 120ms;
            animation-delay: 120ms;
}

  :local(.container) :local(.tip):nth-child(6) {
  background-color: rgba(239, 0, 127, 1);
    -webkit-animation-delay: 150ms;
            animation-delay: 150ms;
}

  :local(.container) :local(.tip):nth-child(7) {
  background-color: rgba(135, 0, 255, 1);
    -webkit-animation-delay: 180ms;
            animation-delay: 180ms;
}

  :local(.container) :local(.tip):nth-child(8) {
  background-color: rgba(0, 157, 255, 1);
    -webkit-animation-delay: 210ms;
            animation-delay: 210ms;
}

  :local(.container) :local(.tip):nth-child(9) {
  background-color: rgba(136, 198, 19, 1);
    -webkit-animation-delay: 240ms;
            animation-delay: 240ms;
}

  :local(.container) :local(.tip):nth-child(10) {
  background-color: rgba(255, 145, 20, 1);
    -webkit-animation-delay: 270ms;
            animation-delay: 270ms;
}

  :local(.container) :local(.tip):nth-child(11) {
  background-color: rgba(239, 0, 127, 1);
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
}

  :local(.container) :local(.tip):nth-child(12) {
  background-color: rgba(135, 0, 255, 1);
    -webkit-animation-delay: 330ms;
            animation-delay: 330ms;
}

@-webkit-keyframes :local(show-hide) {
  0% {
    opacity: 0;
  }
  39% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
   opacity: 1; 
  }
  61% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes :local(show-hide) {
  0% {
    opacity: 0;
  }
  39% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
   opacity: 1; 
  }
  61% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
