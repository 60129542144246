:local(.container) {
  width: 20vh;
  height: 20vh;
  margin: 5vh;

  position: relative
}
:local(.container):local(.whit-label) {
  margin: 5vh 5vh calc(5vh + 1em) 5vh;
}
:local(.container) :local(.indicators) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 20vh;
    height: 20vh;
    border-radius: 100%;
}
:local(.container) :local(.indicators) :local(.indicator) {
      width: 140%;
      height: 0.5vh;
      -webkit-transform-origin: center;
              transform-origin: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      position: absolute;
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
              transform: rotate3d(0, 0, 1, 0deg)
    }
:local(.container) :local(.indicators) :local(.indicator)::before {
  content: '';
  display: block;
  width: 3vh;
  height: 100%;
  background-color: #1d1d1d;
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, #1d1d1d 0 0 10px;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, #1d1d1d 0 0 10px;
}
:local(.container) :local(.indicators) :local(.indicator):local(.on)::before {
  background-color: #79ffb0;
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(136, 198, 19, 1) 0 0 1px;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(136, 198, 19, 1) 0 0 1px;
}
:local(.container) :local(.indicators) :local(.knob) {
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: #1d1d1d;
      border: 5px solid black;
      position: absolute;
      z-index: 5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center
    }
:local(.container) :local(.indicators) :local(.knob)::before {
  content: attr(data-value);
  font-weight: bold;
}
:local(.container) :local(.indicators) :local(.knob)::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
  background-color: transparent;
  box-sizing: border-box;
  border: 2px solid #efefef;
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, #efefef 0 0 10px inset;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, #efefef 0 0 10px inset;
}
:local(.container) :local(.indicators) :local(.knob):local(.orange)::after {
  border: 2px solid rgba(255, 145, 20, 1);
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(255, 145, 20, 1) 0 0 10px inset;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(255, 145, 20, 1) 0 0 10px inset;
}
:local(.container) :local(.indicators) :local(.knob):local(.orange)::before {
  color: rgba(255, 145, 20, 1);
  text-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(255, 145, 20, 1) 0 0 10px;
}
:local(.container) :local(.indicators) :local(.knob):local(.red)::after {
  border: 2px solid rgba(239, 0, 127, 1);
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(239, 0, 127, 1) 0 0 10px inset;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(239, 0, 127, 1) 0 0 10px inset;
}
:local(.container) :local(.indicators) :local(.knob):local(.red)::before {
  color: rgba(239, 0, 127, 1);
  text-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(239, 0, 127, 1) 0 0 10px;
}
:local(.container) :local(.indicators) :local(.knob):local(.purple)::after {
  border: 2px solid rgba(135, 0, 255, 1);
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(135, 0, 255, 1) 0 0 10px inset;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(135, 0, 255, 1) 0 0 10px inset;
}
:local(.container) :local(.indicators) :local(.knob):local(.purple)::before {
  color: rgba(135, 0, 255, 1);
  text-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(135, 0, 255, 1) 0 0 10px;
}
:local(.container) :local(.indicators) :local(.knob):local(.blue)::after {
  border: 2px solid rgba(0, 157, 255, 1);
      -webkit-box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(0, 157, 255, 1) 0 0 10px inset;
              box-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(0, 157, 255, 1) 0 0 10px inset;
}
:local(.container) :local(.indicators) :local(.knob):local(.blue)::before {
  color: rgba(0, 157, 255, 1);
  text-shadow: rgba(0, 0, 0, .5) 0 1px 1px, rgba(0, 157, 255, 1) 0 0 10px;
}
:local(.container) :local(.label) {
    width: 150%;
    font-size: smaller;
    margin-left: -25%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
