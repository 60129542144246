:local(.container) {
  width: 100%;
  height: 100%;
}

  :local(.container) :local(.timing-bar) {
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border: 5px solid #efefef;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: opacity 100ms ease-out, -webkit-box-shadow 100ms ease-out;
    transition: opacity 100ms ease-out, -webkit-box-shadow 100ms ease-out;
    transition: opacity 100ms ease-out, box-shadow 100ms ease-out;
    transition: opacity 100ms ease-out, box-shadow 100ms ease-out, -webkit-box-shadow 100ms ease-out;
    -webkit-box-shadow: 0px 0px 0px 0px #efefef inset;
            box-shadow: 0px 0px 0px 0px #efefef inset
  }

  :local(.container) :local(.timing-bar):local(.timing-bar-blink) {
    -webkit-box-shadow: 0px 0px 2px 2px #efefef inset;
            box-shadow: 0px 0px 2px 2px #efefef inset;
}

  :local(.container) :local(.timing-bar) {
  /* &::before {
      content: '';
      display: block;
      width: 100%;
      height: 50px;
      background-image: url(/assets/images/white-dots.svg);
      background-size: 20px;
      position: absolute;
      top: calc(100% - 28px);
      filter: blur(1px);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, rgba(33, 33, 33, 1) 0%,rgba(135, 0, 255, 0.3) 50%,rgba(33, 33, 33, 1) 100%);
      position: absolute;
      top: calc(100% - 28px);
    } */
}

  :local(.container) :local(.knob-plate) {
    z-index: 20;
    position: absolute;
    width: 30vw;
    height: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  :local(.container) :local(.knob-plate) :local(.knob-bit) {
      width: 5vw;
      height: 5px;
      margin-left: 5vw;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      background-color: #efefef;
    }

  :local(.container) :local(.knob-bar) {
    width: 50vw;
    height: 12vh;
    position: absolute;
    z-index: 19;
    border: solid 2px #efefef;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 12vh;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-out;
    transition: opacity 100ms ease-out;
  }

  :local(.container) :local(.tap-to-spike) {
    position: absolute;
    width: 40vw;
    height: 100%;
    top: 0px;
    right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  :local(.container) :local(.tap-to-spike) :local(.spike-button) {
      height: 40vh;
      width: calc(100% - 10px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }

  :local(.container) :local(.step-cursor) {
    z-index: 10;
    width: 20vh;
    height: 7vh;
    border: 2px solid #efefef;
    border-radius: 20vh;
    left: calc(50% - 10vh);
    top: calc(50% - 3.5vh);
    position: absolute;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    opacity: 0.6;
    background-color: rgba(255, 255, 255, .1);
    -webkit-transition: top 200ms ease-out;
    transition: top 200ms ease-out;
  }

  :local(.container) :local(.step) {
    z-index: 15;
    width: 5vh;
    height: 5vh;
    border-radius: 100%;
    left: calc(50% - 2.5vh);
    background-color: #efefef;
    border: 2px solid #efefef;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(136, 198, 19, 1);
            box-shadow: 0px 2px 3px 0px rgba(136, 198, 19, 1);
  }

  :local(.container) :local(.step) :local {
      -webkit-animation-name: step-animation;
              animation-name: step-animation;
    }

@-webkit-keyframes :local(step-animation) {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(2, 2, 2);
            transform: scale3d(2, 2, 2);
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    opacity: 0;
  }
  70% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2);
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(1, 1, 1);
            transform: translate3d(1, 1, 1);
    background-color: #efefef;
  }
}

@keyframes :local(step-animation) {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(2, 2, 2);
            transform: scale3d(2, 2, 2);
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    opacity: 0;
  }
  70% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2);
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(1, 1, 1);
            transform: translate3d(1, 1, 1);
    background-color: #efefef;
  }
}
