:local(.container) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 10px;
  top: 10px;
  width: 70px;
  height: 70px;
  position: absolute;
  border: 2px solid white;
  border-radius: 50%;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  z-index: 100;
}

  :local(.container) :local {
    -webkit-animation-name: rotate, show;
            animation-name: rotate, show;
  }

  :local(.container) :local(.text) {
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }

@-webkit-keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes :local(rotate) {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes :local(rotate) {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
