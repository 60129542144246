:local(.container) {
  width: 100%;
  height: 100%
}
:local(.container) > canvas {
  display: block;
}
:local(.container):local(.fade-out) {
  -webkit-transition: opacity 400ms ease-out;
  transition: opacity 400ms ease-out;
  opacity: 0;
}
