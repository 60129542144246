:local(.container) {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  :local(.container) :local(.go-to-main-menu) {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  :local(.container) :local(.go-to-main-menu) :local(.intro-logo) {
      width: 80%;
      max-width: 800px;
      -ms-flex-negative: 0;
          flex-shrink: 0;
    }

  :local(.container) :local(.go-to-main-menu) :local(.ncs-logo) {
      height: 50%;
      max-height: 800px;
      -ms-flex-negative: 0;
          flex-shrink: 0;

      -webkit-animation-duration: 300ms;

              animation-duration: 300ms;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
    }

  :local(.container) :local(.go-to-main-menu) :local(.ncs-logo) :local {
        -webkit-animation-name: show;
                animation-name: show;
      }

  :local(.container) :local(.go-to-main-menu) :local(.profile) {
      -webkit-animation-duration: 4800ms;
              animation-duration: 4800ms;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
    }

  :local(.container) :local(.go-to-main-menu) :local(.profile) :local {
        -webkit-animation-name: show-hide;
                animation-name: show-hide;
      }

  :local(.container) :local(.go-to-main-menu) :local(.tap-to-skip) {
      position: absolute;
      bottom: 4vh;
      right: 10vw;
      font-size: small;
      border: 2px solid #efefef;
      border-radius: 20px;
      padding: 2px 20px;
      opacity: 0.7;
    }

@-webkit-keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes :local(show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes :local(show-hide) {
  0% {
    opacity: 0;
  }
  6% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes :local(show-hide) {
  0% {
    opacity: 0;
  }
  6% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
