:local(.container) {
  width: 100%;
  height: 100%;
}

  :local(.container) :local(.qr-container) {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  :local(.container) :local(.qr-container) :local(.qr-body) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: absolute;
      z-index: 5;
    }

  :local(.container) :local(.qr-container) :local(.qr-body) p {
        font-weight: bold;
        font-size: larger;
      }

  :local(.container) :local(.qr-container) :local(.qr-body) :local(.qr) {
        width: 170px;
        height: 170px;
        background-color: #212121;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }

  :local(.container) :local(.qr-container) img {
      width: 140%;
      position: absolute;
      z-index: 0;
      opacity: 0.1;
    }
