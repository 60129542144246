:local(.container) {
  width: 100%;
  height: 100%;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  -webkit-filter: blur(3px);
          filter: blur(3px)
}
:local(.container) > canvas {
  display: block;
}
